// @ts-nocheck
"use client";
import React, { useState, useEffect } from "react";
import { Table, Button, Typography, Spin } from "antd";
import type { TableProps } from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import axiosClient from "../utils/axios.ts";
import useOpenPayouts from "../hooks/use-payouts.tsx";
import { useSelector } from "react-redux";
import { RootState } from "@reduxjs/toolkit/query";
import Tick from "../assets/images/checked.png";

type TableRowSelection<T> = TableProps<T>["rowSelection"];

interface DataType {
  key: React.Key;
  name: string;
  Type: string;
  Amount: string;
  Company: string;
  Date: string;
  edit: any;
  Status: any;
}

const App = ({
  setIsViewOpen,
  activeTab,
  setIsEditOpen,
  column,
  data,
  selectedRowKeys,
  setSelectedRowKeys,
  setSelectedObject,
  setPayoutData,
  payoutData,
  isShowRow,
  isCompany,
  setActiveTab,
  refresh,
}: any) => {
  const { Text } = Typography;
  const [selectedTransactionIds, setSelectedTransactionIds] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: DataType[],
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const newSelectedTransactionIds = {};
    selectedRows.forEach((row) => {
      newSelectedTransactionIds[row.key] = row.transactionIds;
    });
    setSelectedTransactionIds(newSelectedTransactionIds);
  };

  const [isLoading, setIsLoading] = useState(false);

  window.localStorage.setItem("payout_counts", payoutData?.length);

  const ButtonComponent = (item) => {
    return (
      <div className="flex items-center gap-4 justify-center ">
        <button
          onClick={() => {
            setIsViewOpen(true);
            setSelectedObject(item?.item);
          }}
          className="font-semibold text-[#475467] hover:text-[#004EEB]"
        >
          View
        </button>
      </div>
    );
  };
  const StatusBtn = ({ item }) => {
    return (
      <Text
        style={
          item === "Unpaid"
            ? { backgroundColor: "#FFFAEB", color: "#B54708" }
            : item === "Paid"
              ? { backgroundColor: "#ECFDF3", color: "#027A48" }
              : ""
        }
        className="text-[14px] w-auto rounded-[16px] p-1 px-3 capitalize weight"
      >
        {item}
      </Text>
    );
  };

  function formatTableData(data) {
    return data.map((item) => {
      const uniqueKey = `${item?.affiliateId}-${item?.month}`;
      return {
        key: uniqueKey,
        name: item?.affiliateName,
        months: moment(item?.month).format("MMMM YYYY"),
        Type:
          item?.role === "Affiliates"
            ? "Partner"
            : item?.role === "Employee"
              ? "Employee"
              : item?.role === "Super_partner"
                ? "Super Partner"
                : item?.role === "Mini_partner"
                  ? "Mini Partner"
                  : "",
        Amount: `$${item?.amount?.toFixed(2)}`,
        Status: <StatusBtn item={item?.status} />,
        edit: <ButtonComponent item={item} />,
        transactionIds: item?.transactions?.map((t) => t?.user_commission_id), // Store just the user_commission_ids
      };
    });
  }

  const formattedData = formatTableData(payoutData)?.toReversed();

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });

          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const { getPayouts } = useOpenPayouts();

  const handleApprove = () => {
    setIsLoading(true);

    const transactionIdsToUpdate = selectedRowKeys.flatMap(
      (key) => selectedTransactionIds[key],
    );

    axiosClient()
      .post("users-commissions/update-many", {
        payment_status: "Paid",
        is_approved: true,
        commission_paid: 0,
        user_commission_ids: transactionIdsToUpdate,
      })
      .then((res) => {
        refresh();
        toast.success("Approved");
        setIsLoading(false);
      })
      .catch(() => {
        toast.error("Something went wrong");
        setIsLoading(false);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalItems = formattedData?.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const paginatedData = formattedData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  return (
    <div
      style={{
        boxShadow: "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
      }}
      className="rounded-[12px] border border-[#EAECF0] bg-white overflow-hidden"
    >
      {selectedRowKeys?.length > 0 && (
        <>
          <div className="w-full bg-white h-20 items-center  flex justify-end gap-4 px-4">
            <Text className="text-[#475467] selectedText font-semibold ">
              {selectedRowKeys?.length} items selected
            </Text>
            <>
              <Button
                onClick={handleApprove}
                type="primary"
                disabled={isLoading}
                className=" h-[44px] checkedBtn flex items-center bg-[#155EEF] rounded-[8px] font-semibold text-[14px] text-[#fff]"
              >
                {isLoading ? (
                  <Spin className="text-white" />
                ) : (
                  <span>
                    <img src={Tick} alt="" /> Mark as Paid
                  </span>
                )}
              </Button>
            </>
          </div>
        </>
      )}
      <Table
        rowSelection={isShowRow ? false : rowSelection}
        columns={column}
        className="reviewTable news payment"
        dataSource={paginatedData}
        style={{
          borderTop: "1px solid #EAECF0",
        }}
        pagination={false}
      />

      <div className="w-full bg-white h-20 items-center  px-4  flex justify-between gap-4">
        <Text className="text-[#344054] text-[14px] font-medium ">
          Page {currentPage} of {totalPages}
        </Text>

        <div className="flex items-center gap-3 paginationBtn">
          <button
            style={{
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
            className="rounded-[8px] py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          <button
            style={{
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
            className="rounded-[8px] py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;
